<template>
  <v-col class="px-0 py-0">
    <loader v-if="showLoader" />
    <v-col v-else class="px-0 py-0">
      <v-row no-gutters align="center" style="margin-top: 20px">
        <v-col class="py-0" style="padding-right: 10px">
          <charts-card
            :numbers="dailySellTicketStatistic.total"
            :data="dailySellTicketStatistic.interval"
            title="Продано квитків"
          />
        </v-col>
        <v-col class="py-0" style="padding: 0px 10px">
          <charts-card
            :numbers="dailySellSumStatistic.total"
            :data="dailySellSumStatistic.interval"
            title="Загальна сума продажів"
          />
        </v-col>
        <!-- <v-col class="py-0" style="padding-left: 10px">
        <charts-card :numbers="1023" title="Сума застосованих знижок" />
      </v-col> -->
      </v-row>
      <order-history-list />
    </v-col>
  </v-col>
</template>

<script>
import ChartsCard from "../../UI/Cards/chartsCard.vue";
import orderHistoryList from "./orderHistoryList.vue";
import statisticService from "../../../requests/Admin/statisticService";
export default {
  components: { orderHistoryList, ChartsCard },
  data: () => ({
    dailySellTicketStatistic: {},
    dailySellSumStatistic: {},
    showLoader: true,
  }),
  mounted() {
    this.getStatistic();
  },
  methods: {
    async getStatistic() {
      const promises = [
        await statisticService
          .getMainStatistic("Daily", "TicketPayed", "", "")
          .then((res) => {
            if (res.status == "Success") {
              this.dailySellTicketStatistic = res.data?.[0];
            }
          }),
        await statisticService
          .getMainStatistic("Daily", "PricePayed", "", "")
          .then((res) => {
            if (res.status == "Success") {
              this.dailySellSumStatistic = res.data?.[0];
            }
          }),
      ];
      Promise.all(promises).then(() => {
        this.showLoader = false;
      });
    },
  },
};
</script>

<style>
</style>