<template>
  <v-col class="px-0 py-0">
    <v-data-table
      style="margin-top: 32px"
      :headers="headers"
      :items="orderHistory"
      :loading="loading"
      hide-default-footer
      hide-default-header
      :items-per-page="100"
      background-color="red"
      no-data-text="Поки що ви не додали жодного маршруту"
    >
      <template v-slot:header="{ props }">
        <th
          @click="
            head.sortable && head.sortable !== false
              ? setSortField(head.value)
              : ''
          "
          v-for="head in props.headers"
          :key="head.id"
          style="padding: 15px"
        >
          <v-row
            no-gutters
            align="center"
            :justify="head.align ? head.align : 'start'"
            ><output
              style="
                color: #4b5262;
                text-align: center;
                font-family: 'MacPaw Fixel';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                cursor: pointer;
                user-select: none;
              "
              >{{ head.text }}
              <v-icon
                v-if="head.sortable !== false"
                size="25px"
                :color="head.value == sortBy ? 'black' : ''"
                >mdi-menu-swap</v-icon
              >
            </output>
          </v-row>
        </th>
      </template>
      <template v-slot:[`item.id`]="{ item, index }">
        <v-row no-gutters justify="center"></v-row>
        <span class="tableItem">{{ index + 1 }}</span>
      </template>
      <template v-slot:[`item.route`]="{ item }">
        <span class="tableItem pointer" style="color: #144fa9"
          >{{ item?.departure?.translations?.[0]?.name }} -
          {{ item?.destination?.translations?.[0]?.name }}</span
        >
      </template>
      <template v-slot:[`item.user`]="{ item }">
        <span class="tableItem">
          {{ item?.user?.first_name }} {{ item?.user?.last_name }}</span
        >
      </template>
      <template v-slot:[`item.paid_at`]="{ item }">
        <span class="tableItem" v-if="item.paid_at !== null">
          {{
            new Date(item?.paid_at).toLocaleTimeString("uk-UA", {
              hour: "2-digit",
              minute: "2-digit",
              timeZone: "Europe/Kiev",
            })
          }}&nbsp;{{
            new Date(item?.paid_at).toLocaleDateString("uk-UA", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
              timeZone: "Europe/Kiev",
            })
          }}</span
        >   <span class="tableItem" v-else>
          {{
            new Date(item?.created_at).toLocaleTimeString("uk-UA", {
              hour: "2-digit",
              minute: "2-digit",
              timeZone: "Europe/Kiev",
            })
          }}&nbsp;{{
            new Date(item?.created_at).toLocaleDateString("uk-UA", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
              timeZone: "Europe/Kiev",
            })
          }}</span
        >
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div
          class="statusBox statusName"
          style="width: 130px"
          :style="
            item.status == 'Payed'
              ? 'color: #149e51; background: rgba(20, 158, 81, 0.10); '
              : item.status == 'Booking' || item.status == 'PreBooking'
              ? 'color: #E19700; background: rgba(254, 213, 0, 0.10);'
              : 'color: #DF3A3A; background: rgba(223, 58, 58, 0.10);'
          "
        >
          {{
            item.status == "Payed"
              ? "Оплачено"
              : item.status == "Booking" || item.status == "PreBooking"
              ? "Заброньовано"
              : "Скасовано"
          }}
        </div>
        <!-- <v-menu offset-y rounded="lg">
          <template v-slot:activator="{ on, attrs }">
            <div
              class="statusBox statusName"
              v-bind="attrs"
              v-on="on"
              style="width: 130px"
              :style="
                item.status == 'Payed'
                  ? 'color: #149e51; background: rgba(20, 158, 81, 0.10); '
                  : item.status == 'Canceled'
                  ? 'color: #E19700; background: rgba(254, 213, 0, 0.10);'
                  : 'color: #DF3A3A; background: rgba(223, 58, 58, 0.10);'
              "
            >
              {{
                item.status == "Payed"
                  ? "Оплачено"
                  : item.status == "Canceled"
                  ? "Скасовано"
                  : "Заброньовано"
              }}
            </div>
          </template>
          <v-col style="padding: 10px; background-color: #fff">
            <div
              style="
                border-radius: 9px;
                background: #149e511a;
                color: #149e51;
                padding: 8px 16px;
                display: grid;
                place-items: center;
                width: 100%;
                margin-bottom: 10px;
              "
              class="statusName"
              v-if="item.status !== 'Active'"
              @click="changeTripStatus(item, 'Active')"
            >
              Оплачено
            </div>
            <div
              v-if="item.status !== 'Inactive'"
              style="
                border-radius: 9px;
                background: rgba(223, 58, 58, 0.1);
                color: #df3a3a;
                padding: 8px 16px;
                display: grid;
                place-items: center;
              "
              class="statusName"
              @click="changeTripStatus(item, 'Inactive')"
            >
              Скасовано
            </div>
            <div
              v-if="item.status !== 'Onroad'"
              style="
                border-radius: 9px;
                background: rgba(254, 213, 0, 0.1);
                color: #e19700;
                padding: 8px 16px;
                display: grid;
                place-items: center;
                width: 100%;
                margin-top: 10px;
              "
              class="statusName"
              @click="changeTripStatus(item, 'Onroad')"
            >
              Оплачено
            </div>
          </v-col>
        </v-menu> -->
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div style="min-width: 145px">
          <v-row no-gutters align="center" justify="end">
            <div
              class="iconBackground"
              style="margin-right: 12px; width: max-content; padding: 8px"
              @click="(isShowDetailOrderDrawer = true), (detailOrder = item)"
            >
              <p class="detailBtnText">Детальніше</p>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="iconBackground"
                  @click="
                    $router.push({ name: 'editTrip', params: { id: item.id } })
                  "
                >
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="#828282"
                    class="pointer"
                    @click="
                      (symlinks = item.symlinks),
                        (isShowDetailOrderDrawer = true)
                    "
                    >mdi-dots-vertical</v-icon
                  >
                </div>
              </template>
              <span>Деталі</span>
            </v-tooltip>
          </v-row>
        </div>
      </template>
    </v-data-table>
    <pagination
      :page="page"
      :quantityPage="quantityPage"
      @changePage="changePage"
    />

    <detail-order-drawer
      v-if="isShowDetailOrderDrawer"
      :order="detailOrder"
      @close="isShowDetailOrderDrawer = false"
    />
  </v-col>
</template>
    
    <script>
import orderHistoryService from "../../../requests/Admin/orderHistoryService";
import DetailOrderDrawer from "../../UI/detailOrderDrawer.vue";
import pagination from "../../UI/pagination.vue";
import debounce from "lodash/debounce";
//import routesService from "@/requests/admin/routesService";
//import requestFormData from "@/requests/requestFormData";
export default {
  components: { DetailOrderDrawer, pagination },
  data: () => ({
    sortBy: "paid_at",
    sortDesc: true,
    headers: [
      {
        text: "№",
        align: "center",
        value: "id",
        sortable: false,
      },
      { text: "Маршрут", value: "route", align: "start", sortable: false },
      {
        text: "Покупець",
        value: "user",
        align: "start",
        sortable: false,
      },
      {
        text: "Дата купівлі",
        value: "paid_at",
        align: "start",
        sortable: true,
      },
      {
        text: "Куплено (к-сть)",
        value: "seats_count",
        align: "center",
        sortable: false,
      },
      {
        text: "Статус квитка",
        value: "status",
        align: "start",
        sortable: true,
      },
      { text: "Опції", value: "action", sortable: false, align: "center" },
    ],
    isShowDetailOrderDrawer: false,
    loading: false,
    detailOrder: {},
    orderHistory: [],
    page: 1,
    quantityPage: 1,
    search: "",
  }),
  props: {},
  mounted() {
    this.getOrderHistory();
  },
  methods: {
    changePage(page) {
      this.page = page;
      this.showLoader = true;
      this.getOrderHistory();
    },
    setSortField(sortBy) {
      this.sortBy = sortBy;
      this.sortDesc = !this.sortDesc;
    },
    sortOrders: debounce(function () {
      this.loading = true;
      this.getOrderHistory();
    }, 300),
    async getOrderHistory() {
      await orderHistoryService
        .getOrderHistory(
          this.page,
          this.search,
          this.sortBy,
          this.sortDesc == true ? "DESC" : "ASC"
        )
        .then((res) => {
          if (res.status == "Success") {
            this.orderHistory = res.data;
            this.quantityPage = res.pagination.meta.last_page;
            this.showLoader = false;
            this.loading = false;
          }
        });
    },
    changeTripStatus(item, status) {
      let index = this.orderHistory.findIndex((trip) => trip.id == item.id);
      this.orderHistory[index].status = status;
    },
    editTrip() {
      this.$emit("editTrip");
    },
  },
  watch: {
    sortBy: {
      handler() {
        this.sortOrders();
      },
    },
    sortDesc: {
      handler() {
        this.sortOrders();
      },
    },
  },
};
</script>
    
    <style scoped>
.statusBox {
  border-radius: 10px;
  background: rgba(20, 158, 81, 0.1);
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
}
.statusName {
  color: #149e51;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: max-content;
  user-select: none;
  cursor: pointer;
}
.editIcon {
  width: 24px;
  height: 24px;
  background-color: #4b5262;
  -webkit-mask: url("../../../assets/img/iconsSvg/editIcon.svg") no-repeat
    center;
  mask: url("../../../assets/img/iconsSvg/editIcon.svg") no-repeat center;
  cursor: pointer;
}
.iconBackground:hover .editIcon {
  background-color: #fafafa;
}
.detailBtnText {
  color: #4b5262;
  text-align: center;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
}
.iconBackground:hover .detailBtnText {
  color: #fff;
}
</style>
    <style>
.iconBackground:hover .theme--light.v-icon,
.theme--dark.v-icon {
  color: #fafafa !important;
}
.v-pagination__item .v-pagination__item--active {
  background-color: red !important;
}
</style>