<template>
  <div class="charstCard">
    <p style="margin-bottom: 4px">{{ title }}</p>
    <v-row no-gutters align="center">
      <v-col cols="6" class="px-0 py-0">
        <span style="font-size: 14px; color: #989898; font-weight: 400"
          >{{ subtitle ? subtitle : "за день" }}
        </span>
        <p style="font-size: 32px; font-weight: 600; margin-top: 20px">
          <ICountUp :delay="delay" :endVal="numbers" :options="options" />
        </p>
        <v-row no-gutters align="center" v-if="procent">
          <v-icon color="#1eb564" size="16px" style="margin-right: 4px"
            >mdi-arrow-up</v-icon
          >
          <span style="color: #1eb564; font-size: 14px; font-weight: 400"
            >{{ procent }}%</span
          >
        </v-row>
      </v-col>
      <v-col class="py-0" cols="6">
        <div ref="chart" style="width: 100%; height: 120px"></div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as echarts from "echarts";
import ICountUp from "vue-countup-v2";
export default {
  components: {
    ICountUp,
  },
  data: () => ({
    delay: 500,
    options: {
      useEasing: true,
      useGrouping: true,
      separator: "",
      decimal: ".",
      prefix: "",
      suffix: "",
    },
  }),
  props: {
    title: {
      require: true,
    },
    subtitle: {
      require: false,
    },
    numbers: {
      require: true,
    },
    data: {
      require: false,
    },
    procent: {
      require: false,
    },
    type: {
      require: true,
    },
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      const chart = echarts.init(this.$refs.chart);

      const option = {
        tooltip: {},
        xAxis: {
          type: "category",
          boundaryGap: false,
          data:
            this.type == "Daily"
              ? this.data?.map(
                  (data) =>
                    (data = `${data.start.substring(
                      11,
                      16
                    )}-${data.end.substring(11, 16)}`)
                )
              : this.data?.map(
                  (data) =>
                    (data = new Date(
                      data.end.substring(0, 10)
                    ).toLocaleDateString("uk-UA", {
                      month: "short",
                      year: "numeric",
                    }))
                ),
          show: false,
        },
        yAxis: {
          type: "value",
          show: false,
        },
        grid: {
          top: 10,
          left: 10,
          right: 10,
          bottom: 10,
          containLabel: false,
        },
        series: [
          {
            data: this.data?.map((data) => (data = data.count)),
            type: "line",
            smooth: true,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgb(30, 181, 100, 0.5)" },
                { offset: 1, color: "rgb(255, 255, 255, 0)" },
              ]),
            },
            lineStyle: {
              color: "#1EB564",
            },
            itemStyle: {
              color: "#1EB564",
            },
          },
        ],
      };

      chart.setOption(option);
    },
  },
};
</script>

<style scoped>
.charstCard {
  padding: 16px;
  height: max-content;
  width: 100%;
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 10px;
  background: #fff;
}
</style>