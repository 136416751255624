import requestService from "../requestService";
import store from '@/store';

export default {
    async getOrderHistory(page, search, sortBy, sortDesc, isBonus) {
        const response = await requestService.get(`/cp_dash/order?order=${sortBy}&direction=${sortDesc}&limit=10&search=${search}&page=${page}${isBonus ? `&with_bonus=${isBonus}` : ''}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getOrdersByScheduleId(search) {
        const response = await requestService.get(`/cp_dash/order?limit=0&search=${search}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getOrderHistoryByUserId(id, page) {
        const response = await requestService.get(`/cp_dash/order/user/${id}?page=${page}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getRecentlyOrders() {
        const response = await requestService.get(`/cp_dash/order?order=created_at&direction=DESC&limit=6`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
}