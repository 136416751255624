import requestService from "../requestService";
import store from '@/store';

export default {
    async getMainStatistic(frequency, type, start_date, end_date) {
        const response = await requestService.get(`/cp_dash/statistic/orders?frequency=${frequency}&type=${type}&start_date=${start_date}&end_date=${end_date}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getManagerStatistic(frequency, type, start_date, end_date,manager_id) {
        const response = await requestService.get(`/cp_dash/statistic/manager/orders?frequency=${frequency}&type=${type}&start_date=${start_date}&end_date=${end_date}&manager=${manager_id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
}